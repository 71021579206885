/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from "prop-types";
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
//import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import './CardList.css';

export default function CardList(props){    
    const {list} = props;
    const history = useHistory(); 

    /* const handleShareButton = (e, link) => {
        e.preventDefault();
        const url = `${window.location.origin}${link}`
        navigator.clipboard.writeText(url);
        alert(`Enlace a ${url} Copiado.`);       
    } */
    const handleClickContent = (link) => {        
        history.push(link);
    }
    
    return(
        <Grid container style={{marginBottom: '1rem', paddingRight: '15px', paddingLeft: '15px'}} spacing={3}>
            {list.map((item) => (                
            <Grid item key={item.title} xs={12} sm={12} md={4} style={{maxWidth: 'fit-content', textAlign: 'center', marginRight: 'auto', marginLeft: 'auto'}}>
                <Card className="list-item" >
                    <CardActionArea onClick={() => {
                        handleClickContent(item.link);
                    }}>
                    <CardMedia
                        component="img"
                        alt={item.title}
                        height="140"
                        image={item.image}
                        title={item.title}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                        {item.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" style={{textAlign: 'justify'}}>
                        {item.description}
                        </Typography>
                    </CardContent>
                    </CardActionArea>
                    {/* <CardActions>
                    <Button size="small" onClick={(e) => {
                        handleShareButton(e,item.link);
                    }}>                        
                        <Link className="MuiButton-textPrimary">
                            <i className="fas fa-share-alt" />
                            Compartir
                        </Link>                           
                    </Button>
                    <Button size="small">                                                
                        <a href={item.link} target="_blank" rel="noopener noreferrer" className="MuiButton-textPrimary">
                            <i className="fas fa-link" />
                            Abrir
                        </a>                          
                    </Button>
                    </CardActions> */}
                </Card>                
            </Grid>
            ))}
        </Grid>
    );
}


CardList.propTypes = {
    list: PropTypes.array,
}