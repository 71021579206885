import React from "react";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// react components for routing our app without refresh
//import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {send} from '../../../services/email/email';
import { isNumber, isValidEmail } from "utils";
// @material-ui/icons

// core components
import InputAdornment from "@material-ui/core/InputAdornment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import People from "@material-ui/icons/People";
import Email from "@material-ui/icons/Email";
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import WorkIcon from '@material-ui/icons/Work';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import './CivilContent.css';
import { isEmpty } from "utils";

const useStyles = makeStyles(styles);

let successMessage = "Correo Enviado exitosamente.";

export default function ContactContent() {  
  const location = useLocation();
  const subject = location.search.slice(8, location.search.length).toUpperCase();
  const classes = useStyles();
  classes.socialIcons = 'contact-icons';
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState(false);
  const [phone, setPhone] = React.useState('');
  const [phoneError, setPhoneError] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [messageError, setMessageError] = React.useState(false);
  const [topic, setTopic] = React.useState(subject);
  const [topicError, setTopicError] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("Verifique todos los campos requeridos.");

  const handleSendEmail = (e) => {
    e.preventDefault();
    let isErrorOnForm = false;
    if (isEmpty(name)) {
      setNameError(true);
      isErrorOnForm = true;
    } if (!isValidEmail(email)) {
      setEmailError(true);
      isErrorOnForm = true;
    } if (isEmpty(phone) || phone.length !== 8) {
      setPhoneError(true);
      isErrorOnForm = true;
    } if (isEmpty(topic)) {
      setTopicError(true);
      isErrorOnForm = true;
    } if (isEmpty(message)) {
      setMessageError(true);
      isErrorOnForm = true;
    }

    if (isErrorOnForm) {
      setErrorMessage("Verifique todos los campos requeridos.");
      setOpen(true);
      return false;
    } else {      
      send({name,email,phone,topic,message}, setSuccessOpen, setOpen, setErrorMessage, handleClear);  
    }
    
  };

  const handleClear = () => {        
    setName('');
    setNameError(false);
    setPhone('');
    setPhoneError(false);
    setEmail('');
    setEmailError(false);
    setTopic('');
    setTopicError(false);
    setMessage('');
    setMessageError(false);
    setOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
  };

  
  
  return (
    <>
        <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={errorMessage}
        action={
          <React.Fragment>            
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }        
        />

        <Snackbar
        id="success-message"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={successOpen}
        autoHideDuration={3000}
        onClose={handleSuccessClose}
        message={successMessage}
        action={
          <React.Fragment>            
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSuccessClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }        
        />

    <div className={classNames(classes.main, classes.mainRaised, classes.paddingBottom)}>

        <div className={classes.container}>
          <div className={classes.section}>
            <div className={classes.container}>
              <GridContainer justify="center">          
                  <GridItem xs={12} sm={12} md={12} style={{color: 'black'}}>                
                    <h2>La Asesoría Legal que Necesitas</h2>
                    <h4 style={{textAlign: 'justify'}}>                            
                      Estamos para resolver tus dudas o necesidades legales.
                    </h4>
                    <br />
                  </GridItem>
              </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <form className={classes.form}>
                <CardHeader className={classes.cardHeader} style={{textAlign: 'right', backgroundColor: '#1769aa', color: 'white'}} >
                  {/* <h5>Envia un correo o whatsapp</h5> */}
                  <div className={classes.socialLine}>

                    <Button
                      justIcon
                      color="transparent"
                      href="https://www.google.com/maps/place/OFICINA+JURIDICA/@14.04119,-88.9371056,20.75z/data=!4m14!1m7!3m6!1s0x8f6365e9356bf3ed:0x43129805f50a9458!2sAbogado+Erick+Ortez!8m2!3d14.0410561!4d-88.936941!16s%2Fg%2F11h99cmd0x!3m5!1s0x8f6365860c977ce7:0x38d57f9bd758ab04!8m2!3d14.0410896!4d-88.9369351!16s%2Fg%2F11by_dh_fj?hl=en&entry=ttu&g_ep=EgoyMDI0MDkxOC4xIKXMDSoASAFQAw%3D%3D"
                      target="_blank"              
                    >
                      <i className={classes.socialIcons + " fa fa-map-marker"} />
                    </Button>

                    <Button
                      justIcon
                      href="https://www.facebook.com/AbogadoErickOrtezM"
                      target="_blank"
                      color="transparent"                      
                    >
                      <i
                        className={
                          classes.socialIcons + " fab fa-facebook"
                        }
                      />
                    </Button>

                    <Button
                      justIcon
                      href="https://wa.me/50370707070"
                      target="_blank"
                      color="transparent"                      
                    >
                      <i className={classes.socialIcons + " fab fa-whatsapp"} />
                    </Button>
                    
                  </div>
                </CardHeader>
                {/* <p className={classes.divider}>Or Be Classical</p> */}
                <CardBody>
                  <CustomInput
                    labelText="Nombre"
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={name}
                    onChange={(e) => {                      
                      setName(e.target.value);
                    }}
                    onBlur={() => {                      
                      setNameError(isEmpty(name));
                    }}
                    error={nameError}
                    labelError="El nombre no puede estar vacio"
                    inputProps={{
                      type: "text",
                      endAdornment: (
                        <InputAdornment position="end">
                          <People className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText="Correo"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={email}
                    onChange={(e) => {                      
                      setEmail(e.target.value);
                    }}
                    onBlur={() => {                      
                      setEmailError(!isValidEmail(email));
                    }}
                    error={emailError}
                    labelError="El correo no es valido"
                    inputProps={{
                      type: "email",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText="Teléfono"
                    id="phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={phone}
                    onChange={(e) => {                      
                      let value = e.target.value;
                      
                      if (
                        e.nativeEvent.inputType === 'deleteContentBackward' ||
                        e.nativeEvent.inputType === 'deleteContentForward'
                      ) {                        
                        setPhone(value);
                        return;
                      }

                      if (isNumber(value) && phone.length !== 8){                        
                        setPhone(value);                        
                      }
                    }}
                    onBlur={() => {
                      if (isEmpty(phone) || phone.length !== 8) {
                        setPhoneError(true);
                      } else {
                        setPhoneError(false);
                      }
                    }}
                    error={phoneError}
                    labelError="Ingrese un telefono valido"
                    inputProps={{
                      type: "phone",
                      endAdornment: (
                        <InputAdornment position="end">
                          <PhoneAndroidIcon className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText="Asunto"
                    id="subject"                    
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={topic}
                    onChange={(e) => {
                      setTopic(e.target.value);                      
                    }}
                    onBlur={() => {
                        setTopicError(isEmpty(topic));
                    }}
                    error={topicError}
                    labelError="El Asunto no puede ser vacio"
                    inputProps={{
                      type: "text",
                      endAdornment: (
                        <InputAdornment position="end">
                          <WorkIcon className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <FormControl fullWidth >
                    <TextField
                      id="filled-multiline-flexible"
                      label="Mensaje"
                      multiline
                      maxrows={6}
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      onBlur={() => {
                        setMessageError(isEmpty(message));
                      }}
                    />    
                  </FormControl>   */}            
                  <CustomInput
                    labelText="Mensaje"
                    id="message"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    onBlur={() => {
                      setMessageError(isEmpty(message));
                    }}
                    error={messageError}
                    labelError="El Mensaje no puede estar vacio."
                    inputProps={{
                      type: "text",
                      name: 'mensaje',
                      multiline: true,
                      /* endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputIconsColor}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ), */
                      autoComplete: "off",
                    }}
                  />
                </CardBody>

                <CardFooter  style={{justifyContent: 'flex-end'}} >
                  <Button color="white" onClick={handleClear}>
                    Borrar
                  </Button>
                  <Button style={{backgroundColor: '#1769aa'}} onClick={handleSendEmail}>
                    Enviar
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>              
            </div>
        </div>
      </div>
    </div>
    </>
  );
}
