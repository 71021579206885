import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Button from "components/CustomButtons/Button.js";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function ContactButton () {
    const location = useLocation();

    return(
          <div style={{paddingTop: '5px', textAlign: 'right'}}>
            <Button style={{backgroundColor: '#1769aa'}} size="lg">
                <Link to={`/contacto?opcion=${location.pathname.slice(1,location.pathname.length)}`} style={{color: 'white'}}>
                <i className="fas fa-envelope" />
                    &nbsp;Conta&#769;ctanos
                </Link>              
            </Button>
          </div>        
    );
}