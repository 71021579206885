/*eslint-disable*/
import React from "react";
//import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import RoomIcon from '@material-ui/icons/Room';
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// Components for Modal popup
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";

// @material-ui/icons
//import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import './HeaderLinks.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const [classicModal, setClassicModal] = React.useState(false);

  const onClassicModalClick = (event) => {
    setClassicModal(!classicModal);  
  }

  return (
    <>
      <List className={classes.list}>

        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText="Servicios"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
          
            dropdownList={[
              <Link to="/matrimonios" className={classes.dropdownLink}>
                Matrimonios
              </Link>,
              <Link to="/divorcios" className={classes.dropdownLink}>
                Divorcios
              </Link>,
              <Link to="/civil" className={classes.dropdownLink}>
                Civil
              </Link>,
              <Link to="/herencias" className={classes.dropdownLink}>
                Herencias
              </Link>,
              <Link to="/rectificaciones" className={classes.dropdownLink}>
                Rectificación de Partidas
              </Link>,
              <Link to="/empresarial" className={classes.dropdownLink}>
                Empresarial
              </Link>
            ]}
          />
        </ListItem>

        {/* <ListItem className={classes.listItem}> */}          
{/*           <Link to="/#servicios" className={classes.navLink} target="_self">
            Servicios
          </Link> */}
          {/* <a href="/#servicios" className={classes.navLink} target="_self">
            Servicios
          </a>
        </ListItem>  */}

        <ListItem className={classes.listItem}>        
          <Button          
            color="transparent"
            target="_self"
            className={classes.navLink}
            onClick={onClassicModalClick}
          >
            Consulta
          </Button>
        </ListItem>
{/*         <ListItem className={classes.listItem}>
          <Button
            href="/civil"
            color="transparent"
            target="_self"
            className={classes.navLink}
          >
            Civil
          </Button>
          <Link to="/civil" className={classes.navLink}>
            Civil
          </Link>
        </ListItem> */}
{/*         <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText="Empresarial"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}          
            dropdownList={[
              <Link to="/" className={classes.dropdownLink}>
                Titulos
              </Link>,
              <Link to="/" className={classes.dropdownLink}>
                Herencias
              </Link>,
              <Link to="/" className={classes.dropdownLink}>
                Medición de Terrenos
              </Link>
            ]}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/rectificaciones" className={classes.navLink}>
              Rectificación
          </Link>
        </ListItem> */}
        
        <ListItem className={classes.listItem}>          
          <Link to="/biografia" className={classes.navLink}>
            Biografía
          </Link>
        </ListItem>                  
  {/*       <ListItem className={classes.listItem}>
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            id="instagram-twitter"
            title="Follow us on twitter"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              href="https://twitter.com/CreativeTim?ref=creativetim"
              target="_blank"
              color="transparent"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-twitter"} />
            </Button>
          </Tooltip>
        </ListItem> */}
        <ListItem className={classes.listItem}>
          <Tooltip
            id="nav-facebookicon"
            title="Mapa Google"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href="https://www.google.com/maps/place/OFICINA+JURIDICA/@14.04119,-88.9371056,20.75z/data=!4m14!1m7!3m6!1s0x8f6365e9356bf3ed:0x43129805f50a9458!2sAbogado+Erick+Ortez!8m2!3d14.0410561!4d-88.936941!16s%2Fg%2F11h99cmd0x!3m5!1s0x8f6365860c977ce7:0x38d57f9bd758ab04!8m2!3d14.0410896!4d-88.9369351!16s%2Fg%2F11by_dh_fj?hl=en&entry=ttu&g_ep=EgoyMDI0MDkxOC4xIKXMDSoASAFQAw%3D%3D"
              target="_blank"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fa fa-map-marker"} />
            </Button>
          </Tooltip>
        </ListItem>

        <ListItem className={classes.listItem}>
          <Tooltip
            id="nav-facebookicon"
            title="Facebook"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href="https://www.facebook.com/AbogadoErickOrtezM"
              target="_blank"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-facebook"} />
            </Button>
          </Tooltip>
        </ListItem>

        <ListItem className={classes.listItem}>
          <Tooltip
            id="nav-whatsappicon"
            title="Whatsapp"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href="https://wa.me/50379037600"
              target="_blank"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-whatsapp"} />
            </Button>
          </Tooltip>
        </ListItem>

        <ListItem className={classes.listItem}>
          <Tooltip
            id="instagram-facebook"
            title="Contáctanos"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            {/* <Button
              color="transparent"                            
              className={classes.navLink}            
            >
              <i className={classes.socialIcons + " fa fa-envelope"} />
            </Button> */}
             <Link to="/contacto" className={classes.navLink}>              
              <i className={classes.socialIcons + " fa fa-envelope"} />
            </Link>
          </Tooltip>
        </ListItem>

  {/*       <ListItem className={classes.listItem}>
          <Tooltip
            id="instagram-tooltip"
            title="Follow us on instagram"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
              target="_blank"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-instagram"} />
            </Button>
          </Tooltip>
        </ListItem> */}
      </List>

      <DialogAndLoading classicModal={classicModal} onClassicModalClick={onClassicModalClick}/>
    </>    
  );
}

const DialogAndLoading = (props) => {
  const {classicModal, onClassicModalClick} = props;
  const classes = useStyles();
  const iframeElement = (<iframe src="https://www.e.cnr.gob.sv/ConsultaTramite3/consulta/consultaTramite.htm" style={{minWidth: "400px", minHeight: "570px"}} frameBorder="0"></iframe>);

  return( <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={classicModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClassicModalClick}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"        
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClassicModalClick}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>Consultar Trámite</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
          style={{minWidth: "500px", minHeight: "600px", textAlign: "center"}}
        >
          {/* <iframe src="https://www.e.cnr.gob.sv/ConsultaTramite3/Siryc_consulta.htm" style={{minWidth: "300px", minHeight: "570px"}} frameBorder="0"></iframe> */}
          {/* <iframe src="https://www.e.cnr.gob.sv/ServiciosOL/external.htm?pageCode=CONSULTATRAMITE" style={{minWidth: "400px", minHeight: "570px"}} frameBorder="0"></iframe> */}
          {iframeElement ? iframeElement : 'Cargando...'}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
{/*                     <Button color="transparent" simple>
            Nice Button
          </Button> */}
          <Button
            onClick={onClassicModalClick}
            color="danger"
            simple
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>   );
}
