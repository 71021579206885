import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
//import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionBasics from "./Sections/SectionBasics.js";
/* import SectionNavbars from "./Sections/SectionNavbars.js";
import SectionTabs from "./Sections/SectionTabs.js";
import SectionPills from "./Sections/SectionPills.js";
import SectionNotifications from "./Sections/SectionNotifications.js";
import SectionTypography from "./Sections/SectionTypography.js";
import SectionJavascript from "./Sections/SectionJavascript.js";
import SectionCarousel from "./Sections/SectionCarousel.js"; 
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
import SectionLogin from "./Sections/SectionLogin.js";
*/
import SectionServices from "./Sections/SectionServices.js";
//import SectionDownload from "./Sections/SectionDownload.js";
import image1 from "assets/img/home1.jpeg";
import image1Small from "assets/img/home1-small.jpeg";
import image2 from "assets/img/home2.jpg";
import image3 from "assets/img/home3.jpg";
import Hidden from '@material-ui/core/Hidden';

import styles from "assets/jss/material-kit-react/views/components.js";
import './Components.css';

const useStyles = makeStyles(styles);

export default function Components(props) {
  const classes = useStyles();
  const navImageClasses = classNames("mainImages");
  const { ...rest } = props;
  return (
    <div>
      <Header
        brand="Lex Bufete"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "dark",
        }}
        {...rest}
      />
      <Parallax
      filter={false}
        /* image={require("assets/img/mainheader/paperworkatdesk.jpg").default} */
        image={require("assets/img/judgehammer.jpg").default}
      >
      <div className="container-text">
        <div>
          <div className={classes.brand}>
                <h1 className={classes.title}>Lex Bufete</h1>
                <h3 className={classes.subtitle}>
                  Pasión por el derecho.
                </h3>
              </div>
        </div>
      </div>
        {/* <div className={classNames(classes.container)} style={{ margin: '-200px 0 0 12rem', padding: '0' }}>
          <GridContainer >
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>Lex Bufete</h1>
                <h3 className={classes.subtitle}>
                  Pasión por el derecho.
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div> */}
      </Parallax>
      
      <div className={classNames(classes.main, classes.mainRaised)} style={{borderEndEndRadius: '0'}}>

        <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop: '-78px', borderStartStartRadius: '0', textAlign: 'center'}} >
          <GridContainer  style={{textAlign: 'center'}} container>
            <GridItem xs={12} sm={12} md={12} className="images-container">
              <Hidden only={["xl", "lg", "md"]}>
                  <img alt="..." src={image1Small} className={navImageClasses} />
              </Hidden>
              <Hidden only={["sm" ,"xs"]}>
                <img alt="..." src={image1} className={navImageClasses} />
              </Hidden>
              
              <Hidden only={["md", "sm", "xs"]}>
                <img alt="..." src={image2} className={navImageClasses} />
              </Hidden>              
              <Hidden only={["lg", "md", "sm", "xs"]}>
                <img alt="..." src={image3} className={navImageClasses} />
              </Hidden>                              
            </GridItem>        
          </GridContainer>
        </div>
        
        <SectionBasics />
        {/* NOT USED */}
        {/* 
        <SectionNavbars />
        <SectionTabs />
        <SectionPills />
        <SectionNotifications />
        <SectionTypography />
        <SectionJavascript />
        <SectionCompletedExamples />
        <SectionCarousel /> 
        <SectionLogin />
        */}

        {/* NOT USED */}
                
        {/* <GridItem md={12} className={classes.textCenter}>
          <Link to={"/login-page"} className={classes.link}>
            <Button color="primary" size="lg" simple>
              View Login Page
            </Button>
          </Link>
        </GridItem> */}
        {/* <SectionServices /> */}
        {/* <SectionDownload /> */}
      </div>

      <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop: '-78px', borderStartStartRadius: '0', paddingBottom: '2rem'}} >
          <SectionServices />
      </div>
      
      <Footer />
    </div>
  );
}
