import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
//import Gavel from "@material-ui/icons/Gavel";
import Work from "@material-ui/icons/Work";
import School from "@material-ui/icons/School";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
// import profile from "assets/img/bio1.jpg";
import profile from 'assets/img/ErickOrtexLexbufete.png'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
//import InboxIcon from '@material-ui/icons/Inbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
//import DraftsIcon from '@material-ui/icons/Drafts';

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

/* function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
} */

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  //const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="white"
        brand="Lex Bufete"
        rightLinks={<HeaderLinks />}
        fixed        
        changeColorOnScroll={{
          height: 200,
          color: "dark",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/lawbanner1.webp").default}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} style={{border: 'solid white'}}/>
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Lic. Erick Ortez</h3>
                    <h4 style={{marginTop: "-10px"}}>Abogado y Notario</h4>
                    <Button justIcon href="https://www.linkedin.com/in/erick-ortez-81671b1a9/" target="_blank" link className={classes.margin5}>
                      <i className={"fab fa-linkedin"} />
                    </Button>
                    <Button justIcon href="https://x.com/Erick0rtez" target="_blank" link className={classes.margin5}>
                      <i className={"fab fa-twitter"} />
                    </Button>
                    <Button justIcon href="https://www.instagram.com/erickortez/" target="_blank" link className={classes.margin5}>
                      <i className={"fab fa-instagram"} />
                    </Button>
                    <Button justIcon href="https://www.facebook.com/Lic.ErickOrtez" target="_blank" link className={classes.margin5}>
                      <i className={"fab fa-facebook"} />
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>

            <div className={classes.description}>
              <p>
                Licenciado en Derecho con pasión por la justicia y el servicio a la comunidad. 
                Comprometido en brindar asesoría legal de calidad y defender los derechos de mis clientes.
                Siempre dispuesto a ayudar y asesorar en temas legales.{" "}
              </p>
              {/* <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                Deleniti consequuntur dolore numquam blanditiis adipisci saepe provident harum, 
                nam culpa suscipit veniam facilis, quo laboriosam! Possimus consequatur 
                tempora modi cumque exercitationem?{" "}
              </p> */}
            </div>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="info"
                  tabs={[
                    {
                      tabButton: "Estudios Realizados",
                      tabIcon: School,
                      tabContent: (
 /*                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={studio1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio2}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={studio5}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio4}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer> */

                        <div className={classes.root}>
                          <Divider />
                          <List component="nav" aria-label="main mailbox folders">
                            <ListItem button>
                              <ListItemIcon>
                                <CheckBoxIcon />
                              </ListItemIcon>
                              <ListItemText primary="Estudió Jurisprudencia - Abogacía en Universidad Monseñor Oscar Arnulfo Romero" />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <CheckBoxIcon />
                              </ListItemIcon>
                              <ListItemText primary="Estudió Diplomado en Compliance y Buenas Prácticas Corporativas en Pontificia Universidad Católica de Chile" />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <CheckBoxIcon />
                              </ListItemIcon>
                              <ListItemText primary="Estudió Herencias en Postgrados UTEC" />
                            </ListItem>
                          </List>
                          <Divider />
                        </div>                        
                      ),
                    },
                    {
                      tabButton: "Trabajos",
                      tabIcon: Work,
                      tabContent: (
                        <div className={classes.root}>
                          <Divider />
                          <List component="nav" aria-label="main mailbox folders">
                            <ListItem button>
                              <ListItemIcon>
                                <CheckBoxIcon />
                              </ListItemIcon>
                              <ListItemText primary="Abogado en Lex Bufete SA de CV" secondary={<><p className={classes.marginB0}>Desde el 21 de marzo de 2021 hasta la fecha</p> <p>Servicios profesionales de abogacía y notariado; consultorías y representaciones legales</p></>} />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <CheckBoxIcon />
                              </ListItemIcon>
                              <ListItemText primary="Notario en Abogado Erick Ortez" secondary={<><p className={classes.marginB0}>Desde el 10 de noviembre de 2008 hasta la fecha</p> <p>Fundador del despacho jurídico LEX BUFETE</p></>} />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <CheckBoxIcon />
                              </ListItemIcon>
                              <ListItemText primary="Apoderado Legal en Diócesis de Chalatenango" secondary={<><p className={classes.marginB0}>Desde el 1 de diciembre de 2014 hasta el 31 de diciembre de 2020</p></>} />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <CheckBoxIcon />
                              </ListItemIcon>
                              <ListItemText primary="Universidad Dr. Andres Bello" secondary={<><p className={classes.marginB0}>7 de julio de 2009-2013</p><p>Catedrático de las asignaturas Derecho Mercantil y Legislación Turística</p></>} />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <CheckBoxIcon />
                              </ListItemIcon>
                              <ListItemText primary="Trabajó en Panadería y Pastelería Carmencita" secondary={<><p className={classes.marginB0}>Junio de 1995-Enero de 1996</p><p>Hornear pan, lava latas, preparar fórmulas (La fórmula panadera es una tabla sencilla de porcentajes que indica la cantidad en peso de cada ingrediente en la masa, en relación con la cantidad total de harina)</p></>} />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <CheckBoxIcon />
                              </ListItemIcon>
                              <ListItemText primary="Trabajó como Canillita en El Diario de Hoy" secondary={<><p className={classes.marginB0}>Octubre de 1992-Diciembre de 1993</p><p>Vendedor de periódicos, ganando 10 centavos de Colón por periódico vendido. ¡vendiendo un promedio de 150 periódicos diarios!</p></>} />
                            </ListItem>
                          </List>
                          <Divider />
                        </div>  
                      ),
                    },
                    /* {
                      tabButton: "Lex Bufete",
                      tabIcon: Gavel,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <h3>Lex Bufete es...</h3>
                          </GridItem>
                        </GridContainer>
                      ),
                    }, */
                  ]}
                />
              </GridItem>
            </GridContainer>
            
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
