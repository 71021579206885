import React from "react";
// react components for routing our app without refresh
//import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
/* import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js"; */
import serviceData from "data/serviceData";

//import matrimonio from 'assets/img/services/small/weddingrings.jpg'
/* 
import divorcio from 'assets/img/services/small/divorce.jpg'
import civil from 'assets/img/services/small/amendment2.jpg'
import herencia from 'assets/img/services/small/herencia.jpg';
import medicion from 'assets/img/services/small/medicion.jpg';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import empresarial from 'assets/img/services/small/empresarial.jpg';
import rectificaciones from 'assets/img/services/small/amendment3.jpg';
import titulo from 'assets/img/services/small/titulo.jpg' */

import styles from "assets/jss/material-kit-react/views/componentsSections/exampleStyle.js";
import CardList from "components/CardList";

const useStyles = makeStyles(styles);


export default function SectionServices() {
  const classes = useStyles();
  return (
    <div style={{paddingTop: '2rem', color: 'black', textAlign: 'center'}} className="lb-brief-icon">
        {/* <div className={classes.title} style={{textAlign: 'center'}}>
          <h2 id="servicios">Servicios</h2>          
        </div> */}
      <div className={classes.container}>

        <CardList list={serviceData.slice(0,3)} />
        <CardList list={serviceData.slice(3)} />


{/*         <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={2}>
            <Link to="/matrimonios" className={classes.link}>
              <img
                src={matrimonio}
                alt="..."
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <Button color="primary" size="lg" simple>
                Matrimonios
              </Button>
            </Link>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Link to="/divorcios" className={classes.link}>
              <img
                src={divorcio}
                alt="..."
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <Button color="primary" size="lg" simple>
                Divorcios
              </Button>
            </Link>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Link to="/civil" className={classes.link}>
              <img
                src={civil}
                alt="..."
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <Button color="primary" size="lg" simple>
                Civil
              </Button>
            </Link>
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={2}>
            <Link to="/herencias" className={classes.link}>
              <img
                src={herencia}
                alt="..."
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <Button color="primary" size="lg" simple>
                Herencias
              </Button>
            </Link>
          </GridItem>          
          <GridItem xs={12} sm={12} md={2}>
            <Link to="/rectificaciones" className={classes.link}>
              <img
                src={rectificaciones}
                alt="..."
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <Button color="primary" size="lg" simple>
                Rectificaciones
              </Button>
            </Link>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Link to="/empresarial" className={classes.link}>
              <img
                src={empresarial}
                alt="..."
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRounded +
                  " " +
                  classes.imgFluid
                }
              />
              <Button color="primary" size="lg" simple>
                Empresarial
              </Button>
            </Link>
          </GridItem>
        </GridContainer>
 */}
      </div>
    </div>
  );
}
