/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/icons
//import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses} style={{marginTop: '2rem'}}>
      <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>              
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d483.82609545199824!2d-88.9371056!3d14.04119!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f6365860c977ce7%3A0x38d57f9bd758ab04!2sOFICINA%20JURIDICA!5e0!3m2!1sen!2ssv!4v1726960883884!5m2!1sen!2ssv" width="100%" height="100%" style={{border: '0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </GridItem>
          </GridContainer>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>              
              <Link className={classes.block} to="/matrimonios" style={{paddingLeft: '0'}}>
                Matrimonios
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link className={classes.block} to="/divorcios">
                Divorcios
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link className={classes.block} to="/civil">
                Civil
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link className={classes.block} to="/herencias">
                Herencias
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link className={classes.block} to="/rectificaciones">
                Rectificación de Partidas
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link className={classes.block} to="/empresarial">
                Empresarial
              </Link>
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          &copy; {1900 + new Date().getYear()} , Hecho por{" "}
          {/* href="https://www.creative-tim.com?ref=mkr-footer" */}
          <a href="https://www.linkedin.com/in/ernesto-ardon-dev/" target="_blank" style={{color: '#1769aa', fontWeight: 'bolder'}}>Ernesto Ardon</a>{" "}
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
