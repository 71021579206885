import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import classNames from "classnames";

//import Carousel from "react-slick";
//import LocationOn from "@material-ui/icons/LocationOn";

// core components
/* import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js"; */
//import Card from "components/Card/Card.js";
//import Badge from "components/Badge/Badge.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
//import stylesCarousel from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";

/* import image1 from "assets/img/home1.jpeg";
import image2 from "assets/img/home2.jpg";
import image3 from "assets/img/home3.jpg"; */
//import little from 'assets/img/little.png';

const useStyles = makeStyles(styles);
//const carouselStyles = makeStyles(stylesCarousel);

export default function SectionBasics() {
  const iniDate = 2008;
  const classes = useStyles();
  //const carouselClasses = carouselStyles();

/*   const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  }; */
// const navImageClasses = classNames("mainImages");

  return (
    <>
      {/* <GridContainer container style={{textAlign: 'center'}}>
        <GridItem xs={12} sm={12} md={12}>
            <img
                alt="..."
                src={image1}
                className={navImageClasses}
            />
            <img
                alt="..."
                src={image2}
                className={navImageClasses}
            />
            <img
                alt="..."
                src={image3}
                className={navImageClasses}
            />                
        </GridItem>        
      </GridContainer> */}
     <div style={{paddingBottom: '6px', marginBottom: '80px'}} > 
    
      {/*<div className={classes.container}>
         <div className={classes.title} style={{textAlign: 'center'}}>
          <h2>Lex Bufete</h2>
          <h4><strong>{new Date().getFullYear() - 2008}</strong>{" "} trabajando para ti</h4>
        </div> */}

      <div className={classes.container} >
        <div className={classes.title} style={{textAlign: 'center'}}>          
          <h3> <u><strong>{new Date().getFullYear() - iniDate} años</strong>{" "} trabajando para ti </u> </h3>
        </div>
        <h5 style={{textAlign: "justify"}} >
          Lex Bufete, se funda el diez de noviembre del año <strong>{iniDate}</strong>; con la finalidad de ser la alternativa 
          legal a la solución de los problemas jurídicos de la sociedad. La visión profesional del 
          Licenciado Erick Ortez, con lleva a ayudar aquellas personas de escasos recursos económicos para 
          solventar sus problemas legales.
        </h5>
        {/* <div style={{textAlign: 'center'}}>
          <img src={little} />
        </div> */}
      </div>


    </div>
    </>
  );
}
