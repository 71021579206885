import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
/* import LoginPage from "views/LoginPage/LoginPage.js"; */
import MarriagePage from "views/MarriagePage/MarriagePage.js";
import DivorcePage from "views/DivorcePage/DivorcePage.js";
import AmendmentsPage from "views/AmendmentsPage/AmendmentsPage.js";
import CivilPage from "views/CivilPage";
import ContactPage from "views/ContactPage";
import BusinessPage from "views/BusinessPage/BusinessPage";
// import TitlesPage from "views/TitlesPage/TitlesPage";
import InheritancePage from "views/InheritancePage/InheritancePage";
//import LandMeasurePage from "views/LandMeasurePage/LandMeasurePage";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/biografia" component={ProfilePage} />
      {/* <Route path="/login-page" component={LoginPage} /> */}
      <Route path="/matrimonios" component={MarriagePage} />
      <Route path="/civil" component={CivilPage} />
      <Route path="/divorcios" component={DivorcePage} />
      <Route path="/rectificaciones" component={AmendmentsPage} />
      <Route path="/empresarial" component={BusinessPage} />
      {/* <Route path="/titulos" component={TitlesPage} /> */}
      <Route path="/herencias" component={InheritancePage} />
      <Route path="/contacto" component={ContactPage} />
      {/* <Route path="/terrenos-medicion" component={LandMeasurePage} /> */}
      <Route path="/" component={Components} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
