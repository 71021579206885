import React from "react";
// react components for routing our app without refresh
//import { Link } from "react-router-dom";
// @material-ui/core components
import { withStyles, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
/* import boda1 from 'assets/img/bodas/iboda1.jpg'
import boda2 from 'assets/img/bodas/iboda2.jpg'
import boda3 from 'assets/img/bodas/iboda3.jpg'
import boda4 from 'assets/img/bodas/iboda4.jpg' */
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ContactButton from "components/ContactButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
/* import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work'; */
import MenuBookIcon from '@material-ui/icons/MenuBook';
//import BeachAccessIcon from '@material-ui/icons/BeachAccess';


//import styles from "assets/jss/material-kit-react/views/componentsSections/exampleStyle.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
//import './MarriageContent.css';

const useStyles = makeStyles(styles);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(colOne, colTwo) {
  return {colOne, colTwo};
}

const rows = [
  createData('Control sobre tus bienes', 'Tú decides quién hereda tus propiedades y cómo se reparten.'),
  createData('Prevención de conflictos', 'Un testamento claro evita discusiones entre tus familiares.'),
  createData('Protección de tus seres queridos', 'Asegúrate de que estén cuidados económicamente.'),
  createData('Ahorra tiempo y dinero', 'Un testamento válido agiliza el proceso de herencia y evita gastos legales innecesarios.'),
  createData('Paz mental', 'Sabes que tus deseos serán respetados.'),
  createData('Certeza para tus herederos', 'Sabrán exactamente qué les corresponde.'),
  createData('Planificación patrimonial', 'Puedes establecer condiciones específicas para la distribución de tus bienes.'),
];

export default function InheritanceContent() {
  const classes = useStyles();

  //const navImageClasses = classNames("marriageImgs");
  return (
    <>
    <div className={classNames(classes.main, classes.mainRaised, classes.paddingBottom)}>
        <div className={classes.container}>
                <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">          
            <GridItem xs={12} sm={12} md={12} style={{color: 'black'}}>
                {/* https://www.weddingwire.com/wedding-ideas/types-of-wedding-officiants */}
              <h2>Derecho Sucesorio</h2>
              <h5 style={{textAlign: 'justify'}}>                
                El Derecho Sucesorio explica cómo se heredan las propiedades y demás bienes de una persona fallecida. 
                En El Salvador, estas reglas están establecidas en el Código Civil.
                Para comprender el Derecho Sucesorio, es fundamental familiarizarse con algunos conceptos básicos:
              </h5>

              <section>
                <List className={classes.root}>
                    <ListItem>
                        <ListItemAvatar>
                        <Avatar>
                            <MenuBookIcon />
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Herencia" secondary="Conjunto de bienes, derechos y obligaciones que una persona deja tras su fallecimiento" />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                        <Avatar>
                            <MenuBookIcon />
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Heredero" secondary="Persona que recibe la herencia y adquiere los bienes y derechos del fallecido." />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                        <Avatar>
                            <MenuBookIcon />
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Legítima" secondary="Parte de la herencia que la ley reserva a ciertos herederos, como los descendientes o el cónyuge" />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                        <Avatar>
                            <MenuBookIcon />
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Testamento" secondary="documento en el que una persona dispone cómo se distribuirán sus bienes tras su muerte" />
                    </ListItem>
                </List>                
              </section>

              <h5 style={{textAlign: 'justify'}}>                
                Un testamento es una herramienta esencial para garantizar que tus bienes sean distribuidos de acuerdo a tu voluntad después de tu fallecimiento. 
                Te brinda control, evita conflictos y protege a tus seres queridos.
              </h5>

              <h5 style={{textAlign: 'justify'}}>                
                ¿Por qué es importante tener un testamento en El Salvador?
              </h5>


              <section>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                        {/* <TableRow>
                            <StyledTableCell>Conceptos Básicos de las Herencias</StyledTableCell>
                            <StyledTableCell align="right">Importancia del Código Civil en la Aceptación de Herencia</StyledTableCell>
                        </TableRow> */}
                        </TableHead>
                        <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.name}>
                            <StyledTableCell component="th" scope="row">
                                <strong>{row.colOne}: </strong>
                            </StyledTableCell>
                            <StyledTableCell align="left">{row.colTwo}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
              </section>

              <h5 style={{textAlign: 'justify'}}>
                <strong>¿Te gustaría saber más sobre cómo hacer un testamento en El Salvador o sobre algún aspecto específico del derecho sucesorio?</strong> Con gusto podemos ayudarte con lo que necesitas.
              </h5>
              <br />
            <ContactButton />
              
            </GridItem>
        </GridContainer>

          {/* <GridContainer>          
            <GridItem xs={12} sm={12} md={12} lg={12} style={{color: 'black'}}>
              <h4><strong>Regímenes Patrimoniales en el Matrimonio</strong></h4>
              <NavPills
                color="info"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 4, md: 4 },
                  contentGrid: { xs: 12, sm: 8, md: 8 },
                }}
                tabs={[
                  {
                    tabButton: "Separación de bienes",
                    tabIcon: LibraryBooks,
                    tabContent: (
                      <span>
                        <p>
                          En el régimen de separación de bienes, cada uno de los cónyuges conserva la propiedad, la administración y la
                           libre disposición de los bienes que ya tuviere al momento de contraer matrimonio, juntos con los frutos o ganancias que produzcan; 
                           así como sobre los bienes que directa o personalmente llegare a adquirir durante su matrimonio (también con sus frutos o ganancias). 
                        </p>                        
                        <p>
                          Todo lo anterior, con la única restricción de la vivienda familiar. En el caso de no poderse comprobar a cuál de los cónyuges pertenece
                          algún bien, se presumirá que ellos son copropietarios por partes iguales.
                        </p>
                      </span>
                    ),
                  },
                  {
                    tabButton: "Participación en las ganancias",
                    tabIcon: LibraryAdd,
                    tabContent: (
                      <span>
                        <p>
                          En este régimen, cada cónyuge adquiere derecho a participar en las ganancias que llegare a obtener su consorte, durante el tiempo en que dicho 
                          régimen haya estado vigente (generalmente mientras estén casados y no hayan procedido a su disolución). Por lo demás, a cada cónyuge le corresponde 
                          la administración, el disfrute y la libre disposición, tanto de los bienes que ya le pertenecían al momento de contraer matrimonio, 
                          como de aquellos que posteriormente pudo haber adquirido, con la restricción en cuanto a la vivienda familiar. Si conjuntamente adquirieron
                          algún bien, les pertenecerá en proindivisión.
                        </p>                        
                        <p>
                          Este tipo de régimen patrimonial es poco usual y mayormente dedicado a gente muy adinerada, grandes empresarios y artistas o 
                          deportistas de gran renombre, por lo que sus regulaciones nos recuerdan más bien a las instituciones mercantiles que a 
                          las familiares, y hasta se utilizan términos muy propios de la banca y el comercio cuando se toca el tema de su finalización judicial: 
                          insolvencia, quiebra, concurso de acreedores, actos dispositivos o de gestión fraudulentos, disolución y liquidación, etc.
                        </p>
                        <p>
                          Las “ganancias” se determinarán por las diferencias entre los patrimonios inicial y final de cada cónyuge. Si el resultado 
                          fuere “positivo” en los dos patrimonios, el cónyuge que hubiere experimentado menor incremento en el suyo, tendrá derecho 
                          la mitad de la diferencia entre ambos incrementos. Cuando uno solo de los patrimonios se hubiere incrementado durante 
                          la existencia del régimen, el otro cónyuge tendrá derecho a la mitad de ese aumento.
                        </p>
                        <p>
                          Las ganancias así determinadas, deberán pagarse inmediatamente después de liquidado el régimen. A falta de convenio respecto 
                          del pago, el juez podrá adjudicar bienes a cada cónyuge para cancelar su cuota de participación en las ganancias e incluso podrá, 
                          a petición de un cónyuge, ordenar la venta en pública subasta de bienes propiedad del otro cónyuge, para que con su producto 
                          se cancele la cuota de participación en las ganancias.
                        </p>
                      </span>
                    ),
                  },
                  {
                    tabButton: "Comunidad diferida",
                    tabIcon: LibraryAddCheck,
                    tabContent: (
                      <span>
                        <p>
                          En la comunidad diferida, los bienes adquiridos a título oneroso (no gratuito), junto con los frutos, rentas o intereses obtenidos 
                          por cualquiera de los cónyuges, durante la existencia del régimen (generalmente durante su matrimonio) pertenecen a ambos, 
                          y se distribuirán por mitad al disolverse el mismo.
                        </p>                        
                        <p>
                          Se le denomina “diferida” (término que la mayoría de veces deja perplejos a los que desean casarse), pues en realidad no se conforma
                          de inmediato como en el caso de los regímenes anteriores, sino que se tiene por instituido al momento de su disolución (generalmente por un divorcio), 
                          aunque con efectos retroactivos hasta la iniciación del matrimonio o de su constitución por otro medio legal.
                        </p>

                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography className={classes.heading}>En la <strong>Comunidad Diferida</strong>, se consideran propiedad exclusiva de cada cónyuge, los siguientes bienes:</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            
                            <List component="nav" aria-label="main mailbox folders">
                            <ListItem button>
                              <ListItemIcon>                                      
                                <strong>1.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Los que tuviere al momento de casarse o constituirse el régimen por otro medio." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>2.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Los que adquiriere a título gratuito, durante su matrimonio o vigencia del régimen." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>3.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Los que adquiriere en sustitución de cualquiera de los anteriores." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>4.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Los que adquiriere durante el régimen a título oneroso, pero debido a una causa o título de adquisición anterior a la constitución del régimen." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>5.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Indemnizaciones por daños morales y perjuicios a su persona o bienes." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>6.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Objetos de uso estrictamente personal." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>7.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Instrumentos necesarios para el ejercicio de su profesión, arte u oficio, siempre que no formen parte de una empresa o establecimiento común." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>8.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Libros relacionados con su profesión, arte u oficio, las condecoraciones y los objetos de carácter personal sin valor comercial, como los recuerdos de familia." />
                            </ListItem>
                          </List>
                            
                          </AccordionDetails>
                        </Accordion>
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
          </GridContainer> */}
      </div>
    </div>
        </div>
        {/* pictures */}
{/*         <GridContainer style={{textAlign: 'center', marginTop: '2.5rem'}}>
            <GridItem xs={12} sm={12} md={12}>
                <img
                    alt="..."
                    src={boda1}
                    className={navImageClasses}
                />
                <img
                    alt="..."
                    src={boda2}
                    className={navImageClasses}
                />
                <img
                    alt="..."
                    src={boda3}
                    className={navImageClasses}
                />
                <img
                    alt="..."
                    src={boda4}
                    className={navImageClasses}
                />
            </GridItem>
        </GridContainer> */}
    </div>


    </>
  );
}
