import React from "react";
// react components for routing our app without refresh
//import { Link } from "react-router-dom";
// @material-ui/core components
import { withStyles, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
/* import boda1 from 'assets/img/bodas/iboda1.jpg'
import boda2 from 'assets/img/bodas/iboda2.jpg'
import boda3 from 'assets/img/bodas/iboda3.jpg'
import boda4 from 'assets/img/bodas/iboda4.jpg' */
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
/* import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done'; */
import ContactButton from "components/ContactButton";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
/* import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl"; */
/* import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work'; */
//import MenuBookIcon from '@material-ui/icons/MenuBook';
//import BeachAccessIcon from '@material-ui/icons/BeachAccess';


//import styles from "assets/jss/material-kit-react/views/componentsSections/exampleStyle.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
//import './BusinessContent.css';

const useStyles = makeStyles(styles);
useStyles.root = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
};

//theme.palette.common.black
const StyledTableCell = withStyles((theme) => ({    
  head: {
    backgroundColor: '#000000ba',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(colOne, colTwo) {
  return {colOne, colTwo};
}

function _definitions(letters, word, definition){
    return {letters, word, definition};
}

const rows = [
  createData('Se aplica a empresas de todos los tamaños.', 'Se enfoca en grandes corporaciones.'),
  createData('Regula aspectos legales generales de las empresas.', 'Regula aspectos legales específicos de las grandes corporaciones.'),
  createData('No profundiza en el gobierno corporativo.', 'Incluye regulaciones sobre el gobierno corporativo.'),
  createData('No aborda en detalle fusiones y adquisiciones.', 'Regula las fusiones y adquisiciones de empresas.'),
  createData('No considera la responsabilidad de los directivos de manera específica.', 'Establece las responsabilidades legales de los directivos')  
];

const definitions = [
    _definitions('DE', 'Derecho Empresarial', 'El derecho empresarial es una rama del derecho que regula la actividad empresarial de las empresas en general. Se define como el conjunto de normas y disposiciones legales que rigen la formación, organización, funcionamiento y relaciones comerciales de las empresas.'),
    _definitions('E', 'Empresa', 'Una Empresa se refiere a una unidad económica que combina recursos materiales, humanos y financieros con el fin de producir bienes o servicios para su venta en el mercado.'),
    _definitions('CC', 'Contratos Comerciales', 'Los Contratos Comerciales son acuerdos verbales o escritos que establecen las condiciones de una transacción comercial.'),
    _definitions('DL', 'Derecho Laboral', 'El derecho laboral es el conjunto de leyes que regulan la relación entre los trabajadores y sus empleadores. Es decir, establece las reglas que deben seguir tanto las empresas como los empleados en un ambiente de trabajo.'),
    _definitions('DC', 'Derecho Comercial', 'El derecho comercial es el conjunto de leyes que regulan todas las actividades relacionadas con los negocios y el comercio.'),
    _definitions('DT', 'Derecho Tributario', 'El derecho tributario es la rama del derecho que se encarga de todas las leyes y normas relacionadas con los impuestos.'),
    _definitions('PI', 'Propiedad Intelectual', 'El derecho de la propiedad intelectual es el conjunto de leyes que protegen las ideas y creaciones originales de las personas y las empresas.'),
    _definitions('DA', 'Derecho Administrativo', 'El derecho administrativo es el conjunto de leyes que regulan cómo funciona el gobierno y cómo las empresas interactúan con él.'),
    _definitions('DF', 'Derecho Financiero', 'El derecho financiero es el conjunto de leyes que regulan todas las actividades relacionadas con el dinero y las finanzas dentro de las empresas.')
];

export default function BusinessContent() {
  const classes = useStyles();
  const [defWord, setDefWord] = React.useState('Derecho Empresarial');

    const handleClick = (e, definition) => {        
        let textBoxEle = document.querySelector('#definition-box');
        textBoxEle.focus();
        setDefWord(definition.word);
        textBoxEle.innerHTML = definition.definition;
    };

  //const navImageClasses = classNames("marriageImgs");
  return (
    <>
    <div className={classNames(classes.main, classes.mainRaised, classes.paddingBottom)}>
        <div className={classes.container}>
                <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">          
            <GridItem xs={12} sm={12} md={12} style={{color: 'black'}}>
                {/* https://www.weddingwire.com/wedding-ideas/types-of-wedding-officiants */}
              <h2>Derecho Empresarial</h2>
              <h5 style={{textAlign: 'justify'}}>
                El Derecho Empresarial es esencial para el correcto funcionamiento de las empresas, proporcionando un marco legal que garantiza 
                la protección de los derechos de los empresarios y estableciendo reglas claras para la actividad empresarial. 
                Al comprender esta definición y sus alcances, las empresas pueden tomar decisiones informadas y actuar de acuerdo 
                con la normativa legal vigente.              
              </h5>

              <div style={{'border': '1px solid rgba(0, 0, 0, 0.12)', 'padding': '12px'}}>
                
                {/* <textarea ref={textArea} cols="30" style={{'width' : '100%', 'resize': 'none', 'border-color': 'rgba(0, 0, 0, 0.30)', 'padding' : '0.2rem'}} readOnly></textarea> */}
                  <h4>{defWord}</h4>
                 {/*  <FormControl fullWidth style={{marginBottom: '15px'}}>
                    <TextField                      
                      id="definition-box"
                      label=""                        
                      multiline
                      helperText="Presione los conceptos abajo para ver su definición"
                    />    
                  </FormControl> */}
                  <p id="definition-box">El derecho empresarial es una rama del derecho que regula la actividad empresarial de las empresas en general. Se define como el conjunto de normas y disposiciones legales que rigen la formación, organización, funcionamiento y relaciones comerciales de las empresas.</p>
                  <hr />
                  <span style={{fontSize: '12px', color: 'darkgray'}}> <strong>Presione los conceptos abajo para ver su definición</strong> </span> <br />
                  <br />
                      {definitions.map(def => (
                        <Chip style={{marginRight: '0.1rem', marginBottom: '0.3rem'}} key={def.word} avatar={<Avatar>{def.letters}</Avatar>} label={def.word} onClick={(e) => {handleClick(e, def)}} />
                      ))}        
              </div >
                
                <h5 style={{textAlign: 'justify'}}>
                    Para que una empresa funcione bien en El Salvador, necesita conocer y seguir las leyes que la regulan. 
                    El derecho corporativo es el que explica esas leyes y ayuda a las empresas a cumplirlas.
                </h5>

              <h5 style={{textAlign: 'justify'}}>                
                ¿Existe alguna diferencia entre Derecho Empresarial y Derecho Corporativo?
              </h5>


              <section>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell>Derecho Empresarial</StyledTableCell>
                            <StyledTableCell align="right">Derecho Corporativo</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows.map((row) => (                          
                            <StyledTableRow key={row.name}>
                            <StyledTableCell component="th" scope="row">
                                {row.colOne}
                            </StyledTableCell>
                            <StyledTableCell align="right">{row.colTwo}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
              </section>

              <h5 style={{textAlign: 'justify'}}>
                Si tienes una empresa en El Salvador o estás pensando en iniciar una, es fundamental contar con el asesoramiento de un abogado especializado en esta área. 
                Esto te ayudará a proteger tu negocio y alcanzar tus metas. <strong>¿Te gustaría tener una asesoría personalizada?</strong>
              </h5>
              <br />
          {/* <div style={{paddingTop: '5px', textAlign: 'right'}}>
            <Button
              color="danger"
              size="lg"
              href="/contacto?opcion=matrimonio"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fas fa-envelope" />
              &nbsp;Conta&#769;ctanos
            </Button>
          </div> */}
              <ContactButton />
            </GridItem>
        </GridContainer>

          {/* <GridContainer>          
            <GridItem xs={12} sm={12} md={12} lg={12} style={{color: 'black'}}>
              <h4><strong>Regímenes Patrimoniales en el Matrimonio</strong></h4>
              <NavPills
                color="info"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 4, md: 4 },
                  contentGrid: { xs: 12, sm: 8, md: 8 },
                }}
                tabs={[
                  {
                    tabButton: "Separación de bienes",
                    tabIcon: LibraryBooks,
                    tabContent: (
                      <span>
                        <p>
                          En el régimen de separación de bienes, cada uno de los cónyuges conserva la propiedad, la administración y la
                           libre disposición de los bienes que ya tuviere al momento de contraer matrimonio, juntos con los frutos o ganancias que produzcan; 
                           así como sobre los bienes que directa o personalmente llegare a adquirir durante su matrimonio (también con sus frutos o ganancias). 
                        </p>                        
                        <p>
                          Todo lo anterior, con la única restricción de la vivienda familiar. En el caso de no poderse comprobar a cuál de los cónyuges pertenece
                          algún bien, se presumirá que ellos son copropietarios por partes iguales.
                        </p>
                      </span>
                    ),
                  },
                  {
                    tabButton: "Participación en las ganancias",
                    tabIcon: LibraryAdd,
                    tabContent: (
                      <span>
                        <p>
                          En este régimen, cada cónyuge adquiere derecho a participar en las ganancias que llegare a obtener su consorte, durante el tiempo en que dicho 
                          régimen haya estado vigente (generalmente mientras estén casados y no hayan procedido a su disolución). Por lo demás, a cada cónyuge le corresponde 
                          la administración, el disfrute y la libre disposición, tanto de los bienes que ya le pertenecían al momento de contraer matrimonio, 
                          como de aquellos que posteriormente pudo haber adquirido, con la restricción en cuanto a la vivienda familiar. Si conjuntamente adquirieron
                          algún bien, les pertenecerá en proindivisión.
                        </p>                        
                        <p>
                          Este tipo de régimen patrimonial es poco usual y mayormente dedicado a gente muy adinerada, grandes empresarios y artistas o 
                          deportistas de gran renombre, por lo que sus regulaciones nos recuerdan más bien a las instituciones mercantiles que a 
                          las familiares, y hasta se utilizan términos muy propios de la banca y el comercio cuando se toca el tema de su finalización judicial: 
                          insolvencia, quiebra, concurso de acreedores, actos dispositivos o de gestión fraudulentos, disolución y liquidación, etc.
                        </p>
                        <p>
                          Las “ganancias” se determinarán por las diferencias entre los patrimonios inicial y final de cada cónyuge. Si el resultado 
                          fuere “positivo” en los dos patrimonios, el cónyuge que hubiere experimentado menor incremento en el suyo, tendrá derecho 
                          la mitad de la diferencia entre ambos incrementos. Cuando uno solo de los patrimonios se hubiere incrementado durante 
                          la existencia del régimen, el otro cónyuge tendrá derecho a la mitad de ese aumento.
                        </p>
                        <p>
                          Las ganancias así determinadas, deberán pagarse inmediatamente después de liquidado el régimen. A falta de convenio respecto 
                          del pago, el juez podrá adjudicar bienes a cada cónyuge para cancelar su cuota de participación en las ganancias e incluso podrá, 
                          a petición de un cónyuge, ordenar la venta en pública subasta de bienes propiedad del otro cónyuge, para que con su producto 
                          se cancele la cuota de participación en las ganancias.
                        </p>
                      </span>
                    ),
                  },
                  {
                    tabButton: "Comunidad diferida",
                    tabIcon: LibraryAddCheck,
                    tabContent: (
                      <span>
                        <p>
                          En la comunidad diferida, los bienes adquiridos a título oneroso (no gratuito), junto con los frutos, rentas o intereses obtenidos 
                          por cualquiera de los cónyuges, durante la existencia del régimen (generalmente durante su matrimonio) pertenecen a ambos, 
                          y se distribuirán por mitad al disolverse el mismo.
                        </p>                        
                        <p>
                          Se le denomina “diferida” (término que la mayoría de veces deja perplejos a los que desean casarse), pues en realidad no se conforma
                          de inmediato como en el caso de los regímenes anteriores, sino que se tiene por instituido al momento de su disolución (generalmente por un divorcio), 
                          aunque con efectos retroactivos hasta la iniciación del matrimonio o de su constitución por otro medio legal.
                        </p>

                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography className={classes.heading}>En la <strong>Comunidad Diferida</strong>, se consideran propiedad exclusiva de cada cónyuge, los siguientes bienes:</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            
                            <List component="nav" aria-label="main mailbox folders">
                            <ListItem button>
                              <ListItemIcon>                                      
                                <strong>1.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Los que tuviere al momento de casarse o constituirse el régimen por otro medio." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>2.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Los que adquiriere a título gratuito, durante su matrimonio o vigencia del régimen." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>3.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Los que adquiriere en sustitución de cualquiera de los anteriores." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>4.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Los que adquiriere durante el régimen a título oneroso, pero debido a una causa o título de adquisición anterior a la constitución del régimen." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>5.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Indemnizaciones por daños morales y perjuicios a su persona o bienes." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>6.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Objetos de uso estrictamente personal." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>7.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Instrumentos necesarios para el ejercicio de su profesión, arte u oficio, siempre que no formen parte de una empresa o establecimiento común." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>8.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Libros relacionados con su profesión, arte u oficio, las condecoraciones y los objetos de carácter personal sin valor comercial, como los recuerdos de familia." />
                            </ListItem>
                          </List>
                            
                          </AccordionDetails>
                        </Accordion>
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
          </GridContainer> */}
      </div>
    </div>
        </div>
        {/* pictures */}
{/*         <GridContainer style={{textAlign: 'center', marginTop: '2.5rem'}}>
            <GridItem xs={12} sm={12} md={12}>
                <img
                    alt="..."
                    src={boda1}
                    className={navImageClasses}
                />
                <img
                    alt="..."
                    src={boda2}
                    className={navImageClasses}
                />
                <img
                    alt="..."
                    src={boda3}
                    className={navImageClasses}
                />
                <img
                    alt="..."
                    src={boda4}
                    className={navImageClasses}
                />
            </GridItem>
        </GridContainer> */}
    </div>


    </>
  );
}
