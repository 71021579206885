const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const isEmptyRegex = /^\s*$/;
const salvadoranPhoneNumberRegex = /^[27]\d{7}$/;
const numberRegex = /^\d+$/;

  const isEmpty = (value) => {
      if(value.length === 0 || value === null || value === undefined || value === "" || isEmptyRegex.test(value)) {
        return true;
      } else {
        return false;
      }
  };

  const isValidEmail = (value) => {
    return emailRegex.test(value);
  };

  const isValidPhoneNumber = (value) => {
    return salvadoranPhoneNumberRegex.test(value);
  }

  const isNumber = (value) => {
    return numberRegex.test(value);
  }

  export {
    isEmpty,
    isValidEmail,    
    isValidPhoneNumber,
    isNumber,
    numberRegex,
  }