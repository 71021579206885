import matrimonio from '../assets/img/services/small/weddingrings.jpg'
import divorcio from '../assets/img/services/small/divorce.jpg'
import civil from '../assets/img/services/derecho-civil.jpg'
import herencia from '../assets/img/services/small/herencia.jpg';
import empresarial from '../assets/img/services/small/empresarial.jpg';
import rectificaciones from '../assets/img/services/small/amendment3.jpg';

const serviceData = [
    {
        image: matrimonio,
        title: "Matrimonio",
        description: 'Contamos con la experiencia en derecho matrimonial para que tu enlace sea legal y sin complicaciones.',
        index: 1,
        link: '/matrimonios'
    },
    {
        image: divorcio,
        title: "Divorcio",
        description: 'Representación legal en procesos de divorcio de mutuo acuerdo o contencioso.',
        index: 2,
        link: '/divorcios'
    },
    {
        image: civil,
        title: "Civil",
        description: 'Asesoría legal en todo tipo de asuntos civiles. Defendemos tus derechos y buscamos soluciones justas y efectivas.',
        index: 3,
        link: '/civil'
    },
     {
        image: herencia,
        title: "Herencias",        
        description: 'Te ayudamos a tramitar la sucesión de bienes de forma rápida y segura, evitando conflictos legales.',
        index: 4,
        link: '/herencias'
    },
    {
        image: rectificaciones,
        title: "Rectificaciones",
        description: 'Realizamos trámites de rectificación de actas de nacimiento, matrimonio y otros registros civiles.',
        index: 5,
        link: '/rectificaciones'
    },
    {
        image: empresarial,
        title: "Empresarial",        
        description: 'Soluciones legales a medida para tu negocio. Contratos, constitución de sociedades, propiedad intelectual, etc.',
        index: 6,
        link: '/empresarial'
    }
];

export default serviceData;