import React from "react";
// react components for routing our app without refresh
//import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Cancel, CheckBox, PeopleOutline, PlusOne } from "@material-ui/icons";
import ContactButton from "components/ContactButton";

import Divider from '@material-ui/core/Divider';
import NavPills from "components/NavPills/NavPills.js";
//import InboxIcon from '@material-ui/icons/Inbox';
//import CheckBoxIcon from '@material-ui/icons/CheckBox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Paper } from "@material-ui/core";


//import styles from "assets/jss/material-kit-react/views/componentsSections/exampleStyle.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import './DivorceContent.css';

const useStyles = makeStyles(styles);


export default function DivorceContent() {
  const classes = useStyles();

  //const navImageClasses = classNames("marriageImgs");
  return (
    <>
    <div className={classNames(classes.main, classes.mainRaised, classes.paddingBottom)}>
        <div className={classes.container}>
                <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">          
            <GridItem xs={12} sm={12} md={12} style={{color: 'black'}}>                
              <h2>Mutuo consentimiento, Separación por uno o más años...</h2>
              <h4 style={{textAlign: 'justify'}}>                            
                El divorcio es la disolución del vínculo matrimonial dictaminada únicamente por un Juez de Familia, 
                y se materializa a través de una sentencia tras un proceso que puede ser extenso. En El Salvador, 
                hay distintos tipos de divorcio con distintos requisitos legales. <i>Una asistencia especializada es crucial 
                para llegar a la mejor conclusión posible</i>.
                
              </h4>
              <br />
            </GridItem>
        </GridContainer>        
      </div>
    </div>
        </div>

      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
          <Paper elevation={2} style={{padding: '1px 0'}}>
          <NavPills
            alignCenter
            color="info"
            tabs={[
              {
                tabButton: "Mutuo Consentimiento",
                tabIcon: PeopleOutline,
                tabContent: (
                  <div className={classes.root}>
                    <Divider />                                                            
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12} className={classes.divorceTypeBox}>
                          
                          <h4 style={{textAlign: 'justify'}}>                            
                            Este tipo de divorcio ocurre cuando ambos cónyuges están de acuerdo en poner fin al matrimonio. 
                            Ambas partes deben estar dispuestas a cooperar y llegar a un acuerdo en cuanto a aspectos fundamentales 
                            como la custodia de los hijos, la división de bienes y otros asuntos relacionados. En este caso, se debe 
                            elaborar un convenio de divorcio que establezca los términos y condiciones acordados por ambas partes.                                 
                          </h4>
                          
                          {/* <br /> */}
                          {/* <Button
                            color="danger"
                            size="lg"
                            href="/contacto?opcion=matrimonio"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fas fa-envelope" />
                            &nbsp;Conta&#769;ctanos
                          </Button> */}
                        </GridItem>

                      </GridContainer>
                  </div>                        
                ),
              },
              {
                tabButton: "Separación por uno o más años",
                tabIcon: PlusOne,
                tabContent: (
                  <div className={classes.root}>
                    <Divider />
                      <GridContainer justify="center">          
                        <GridItem xs={12} sm={12} md={12} className={classes.divorceTypeBox}>                              
                          <h4 style={{textAlign: 'justify'}}>                            
                            Este tipo de divorcio se produce cuando los cónyuges han estado separados de hecho durante 
                            un período prolongado, generalmente un año o más. La separación debe ser voluntaria y sin 
                            reconciliación durante ese período. Para solicitar el divorcio por esta causal, es necesario 
                            presentar pruebas que demuestren la separación prolongada ante un tribunal.
                          </h4>
                          {/* <br /> */}
                          {/* <Button
                            color="danger"
                            size="lg"
                            href="/contacto?opcion=matrimonio"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fas fa-envelope" />
                            &nbsp;Conta&#769;ctanos
                          </Button> */}
                        </GridItem>
                      </GridContainer>
                  </div>  
                ),
              },
              {
                tabButton: "Vida común intolerable",
                tabIcon: Cancel,
                tabContent: (
                  <div className={classes.root}>
                    <Divider />
                      <GridContainer justify="center">          
                        <GridItem xs={12} sm={12} md={12} className={classes.divorceTypeBox}>                              
                          <h4 style={{textAlign: 'justify'}}>                            
                            Este tipo de divorcio se basa en la intolerancia de caracteres, lo que significa que la convivencia 
                            se ha vuelto insoportable debido a diferencias irreconciliables entre los cónyuges. Para solicitar 
                            el divorcio bajo esta causal, es fundamental presentar pruebas sólidas y convincentes de la incompatibilidad 
                            y el deterioro de la relación.
                          </h4>
                          {/* <br /> */}
{/*                           <Button
                            color="danger"
                            size="lg"
                            href="/contacto?opcion=matrimonio"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fas fa-envelope" />
                            &nbsp;Conta&#769;ctanos
                          </Button> */}
                        </GridItem>
                      </GridContainer>
                  </div>  
                ),
              },
              {
                tabButton: "Requerimientos",
                tabIcon: CheckBox,
                tabContent: (
                  <div className={classes.root}>
                    <Divider />
                      <GridContainer justify="center">          
                        <GridItem xs={12} sm={12} md={12} className={classes.divorceTypeBox}>

                          <h5>Para <strong>Separación por uno o más años</strong> y <strong>Vida común intolerable</strong> los requerimientos son:</h5>

                          <List component="nav" aria-label="main mailbox folders">
                            <ListItem button>
                              <ListItemIcon>                                      
                                <strong>1.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Copia de DUI de la persona solicitante." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>2.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Partida de nacimiento de los cónyuges." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>3.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Partida de matrimonio." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>4.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Partida de nacimiento de todas las hijas e hijos procreados en común." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>5.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Dirección de residencia o de trabajo de la persona a demandar." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>6.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Copia de DUI de 2 testigos que den fe de la separación o de la violencia en su caso." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>7.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Si existen hijas o hijos menores de edad, presentar constancias de estudio y facturas de gastos escolares." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>8.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Cualquier otra documentación que se requiera para este trámite." />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon>
                                <strong>9.</strong>
                              </ListItemIcon>
                              <ListItemText primary="Presentar dos testigos con su copia de DUI que den fe de los hechos (En los procesos de familia son permitidos los familiares mayores de 18 años)." />
                            </ListItem>
                          </List>
                        
                        </GridItem>
                      </GridContainer>  
                    
                  </div>  
                ),
              },
            ]}
          />
          </Paper>
          
        </GridItem>

      </GridContainer>

    <div className={classes.container}>
      <div className={classes.section}>
        <div className={classes.container}>
          <ContactButton />
        </div>
      </div>
    </div>
    </div>


    </>
  );
}
