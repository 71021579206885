import React from "react";
// react components for routing our app without refresh
//import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/icons

// core components
import clsx from 'clsx';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import ContactButton from "components/ContactButton";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { grey } from '@material-ui/core/colors';
/* import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share'; */
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import MoreVertIcon from '@material-ui/icons/MoreVert';

//import styles from "assets/jss/material-kit-react/views/componentsSections/exampleStyle.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import nacimientoRegistro from 'assets/img/amendment/nacimiento-registro.jpg'
import bodaRegistro from 'assets/img/amendment/boda-registro.jpg';
import defuncionRegistro from 'assets/img/amendment/defuncion-registro.jpg';
import './AmendmentsContent.css';

const useStyles = makeStyles(styles);


export default function AmendmentsContent() {
  const classes = useStyles();
  //const navImageClasses = classNames("marriageImgs");
  return (
    <>
    <div className={classNames(classes.main, classes.mainRaised, classes.paddingBottom)}>
        <div className={classes.container}>
            <div className={classes.section}>
                <div className={classes.container}>
                    <GridContainer justify="center">          
                        <GridItem xs={12} sm={12} md={12} style={{color: 'black'}}>                
                        <h2 style={{fontSize: '2.1rem'}}> Rectificación de Partidas de Nacimiento, Matrimonio, Defunción.</h2>
                        <h4 style={{textAlign: 'justify'}}>                
                            La corrección se lleva a cabo cuando durante el proceso de registro se incluyen de manera incorrecta detalles en el acta. 
                            Por ejemplo, si se invierten los apellidos, si se introducen fechas que no existen o si se cometen errores ortográficos 
                            al escribir o registrar la información.
                        </h4>
                        <br />
{/*                         <div>
                        <Button
                            color="danger"
                            size="lg"
                            href="/contacto?opcion=matrimonio"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className="fas fa-envelope" />
                            &nbsp;Conta&#769;ctanos
                        </Button>
                        </div> */}
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                            <AmendmentCard avatar="Na" subheader="Nacimiento" image={nacimientoRegistro} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <AmendmentCard avatar="Ma" subheader="Matrimonio" image={bodaRegistro} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <AmendmentCard avatar="De" subheader="Defunción" image={defuncionRegistro} />
                        </GridItem>
                    </GridContainer>
                    
                    <ContactButton />    
                </div>
                
            </div>
        </div>
    </div>
    </>
  );
}

const cardStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: grey[700],
  },
}));

const AmendmentCard = (props) => {
    const classes = cardStyles();
    const [expanded, setExpanded] = React.useState(false);
    const title = "Rectificación partida de";
    const {avatar, subheader, image} = props;
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return(
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {avatar}
          </Avatar>
        }
/*         action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        } */
       title={title}
       subheader={subheader}
      />
      <CardMedia
        className={classes.media}
        image={image}
        title={title.concat(" ").concat(subheader)}
      />
{/*       <CardContent>
        <Typography variant="body1" color="textPrimary" component="p">
          Requerimientos:
        </Typography>
      </CardContent> */}
      <CardActions disableSpacing onClick={handleExpandClick} style={{cursor: 'pointer'}}>
        {/* <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
        <Typography variant="body1" color="textPrimary" component="p">
          <strong>Requerimientos:</strong>
        </Typography>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          /* onClick={handleExpandClick} */
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
            <List component="nav" aria-label="main mailbox folders">
                <ListItem button>
                    <ListItemIcon>                                      
                    <strong>1.</strong>
                    </ListItemIcon>
                    <ListItemText primary="Fotocopia del DUI ampliado a 150% del solicitante" />
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                    <strong>2.</strong>
                    </ListItemIcon>
                    <ListItemText primary="Acta de suspensión de tramite de la institución que advirtió el error u omisión" />
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                    <strong>3.</strong>
                    </ListItemIcon>
                    <ListItemText primary="Fotocopia de la Certificación del Registro de Cédula de Identidad Personal del solicitante" />
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                    <strong>4.</strong>
                    </ListItemIcon>
                    <ListItemText primary="Certificación original y reciente de la partida de nacimiento, sometida a rectificación" />
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                    <strong>5.</strong>
                    </ListItemIcon>
                    <ListItemText primary="Certificación de la partida de nacimiento original y reciente del padre o madre del solicitante, según corresponda probar, el error advertido" />
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                    <strong>6.</strong>
                    </ListItemIcon>
                    <ListItemText primary="Fotocopia del DUI ampliado a 150% del padre o madre del solicitante, según corresponda" />
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                    <strong>7.</strong>
                    </ListItemIcon>
                    <ListItemText primary="Sentencia de ser partida subsidiaria" />
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                    <strong>8.</strong>
                    </ListItemIcon>
                    <ListItemText primary="Otros" />
                </ListItem>
            </List>
        </CardContent>
      </Collapse>
    </Card>
    )
}


AmendmentCard.propTypes = {
  avatar: PropTypes.string,
  subheader: PropTypes.string,
  image: PropTypes.object,
  children: PropTypes.node,
};
