import React from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import CivilContent from '../Components/CivilContent';

// Sections for this page
//import DownloadFiles from "components/DownloadFiles/DownloadFiles.js"

const useStyles = makeStyles(styles);

export default function CivilPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        brand="Lex Bufete"
        rightLinks={<HeaderLinks />}
        fixed        
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "dark",
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/services/derecho-civil.jpg").default} style={{height: "45vh"}}>
        <div className={classes.container} >
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Civil</h1>
{/*               <h4 className={classes.subtitle}>
                Documentos descargables.
              </h4> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
{/*       <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum ut quod temporibus commodi tenetur qui deleniti perspiciatis, iure quae placeat? Ab porro perspiciatis at mollitia nostrum eos id reprehenderit in!
          </p>
        </div>
      </div> */}
      <CivilContent />
      <Footer />
    </div>
  );
}
