import { serviceId, templateId } from "./init"

const send = (contact, setSuccessOpen, setOpen, setErrorMessage, handleClear) => {
    const emailForm = document.createElement("form");

    let inputTo = document.createElement("input");
    let inputFrom = document.createElement("input");
    let inputPhone = document.createElement("input");
    let inputEmail = document.createElement("input");
    let inputReplyEmail = document.createElement("input");
    let textMessage = document.createElement("textarea");

    inputTo.name = "to_name";
    inputTo.value = "Erick Ortez";

    inputFrom.name = "from_name";
    inputFrom.value = contact.name;

    inputPhone.name = "contact_phone";
    inputPhone.value = contact.phone;

    inputReplyEmail.name = "reply_to";
    inputReplyEmail.value = contact.email;

    inputEmail.name = "contact_email";
    inputEmail.value = contact.email;

    textMessage.name = "message";
    textMessage.value = contact.message;        

    emailForm.appendChild(inputTo);
    emailForm.appendChild(inputFrom);
    emailForm.appendChild(inputPhone);
    emailForm.appendChild(inputReplyEmail);
    emailForm.appendChild(inputEmail);
    emailForm.appendChild(textMessage);

    window?.emailjs.sendForm(serviceId, templateId, emailForm).then((response) => {
        console.log(response);        
        setSuccessOpen(true);
        handleClear();
    }, 
    (error) => {
        setErrorMessage("¡Hubo un problema enviando el correo, Intente más tarde!");
        setOpen(true);
        console.log(error);
        handleClear();
    },)
}

export {
    send,
}