import React from "react";
// react components for routing our app without refresh
//import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core";
import ContactButton from "components/ContactButton";
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
/* import { Cancel, CheckBox, PeopleOutline, PlusOne } from "@material-ui/icons";
import Divider from '@material-ui/core/Divider';
import NavPills from "components/NavPills/NavPills.js"; */
//import InboxIcon from '@material-ui/icons/Inbox';
//import CheckBoxIcon from '@material-ui/icons/CheckBox';
//import { Paper } from "@material-ui/core";


//import styles from "assets/jss/material-kit-react/views/componentsSections/exampleStyle.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import './CivilContent.css';

const useStyles = makeStyles(styles);


export default function CivilContent() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  //const navImageClasses = classNames("marriageImgs");
  return (
    <>
    <div className={classNames(classes.main, classes.mainRaised, classes.paddingBottom)}>
        <div className={classes.container}>
                <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">          
            <GridItem xs={12} sm={12} md={12} style={{color: 'black'}}>                
              <h2>Asesoría legal integral en materia de Derecho Civil</h2>
              <h4 style={{textAlign: 'justify'}}>                            
                Ofrecemos una atención completa y personalizada para resolver sus dudas o necesidades legales 
                relacionadas a contratos, obligaciones, sucesiones y más.
              </h4>
              <br />
            </GridItem>
        </GridContainer>        
      </div>
    </div>
        </div>

      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
      <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Elaboración de contratos civiles a la medida de sus necesidades</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Redactamos y revisamos minuciosamente sus contratos civiles para garantizar 
            la seguridad jurídica de sus intereses. Nos especializamos en contratos de compraventa, alquiler, arrendamiento, donación, sociedad, y más.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Tramitación eficiente de diligencias civiles</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Nos encargamos de gestionar de manera ágil y eficaz sus trámites legales ante las entidades competentes, ahorrándole tiempo y esfuerzo.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Litigación experta en procesos civiles</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Representamos sus intereses con profesionalismo y dedicación en todo tipo de procesos civiles, velando por la protección de sus derechos.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>Consultoría jurídica especializada en Código Civil</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Brindamos asesoría personalizada sobre la aplicación e interpretación de las normas del Código Civil, 
            para que usted pueda tomar decisiones informadas y acertadas.
          </Typography>
        </AccordionDetails>
      </Accordion>
          
        </GridItem>

        
      </GridContainer>

        <div className={classes.container}>
          <div className={classes.section}>
            <div className={classes.container}>
              <ContactButton />
            </div>
          </div>
        </div>

    </div>


    </>
  );
}
